* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  font-size: 16px;
  --box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.5),
    -5px -5px 15px rgba(255, 255, 255, 0.1);
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: 'Poppins', sans-serif;
  line-height: 1.6;
  user-select: none;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: whitesmoke;
}

nav {
  list-style: none;
}

.container {
  width: min(100%, 1430px);
  margin: 0 auto;
  transition-duration: 200ms;
}

.hidden {
  display: none;
}

.visible {
  display: block;
}

/* nav bar section */
header {
  width: 100%;
  padding: 1em;
  position: fixed;
  background: rgb(23, 23, 23);
  color: rgb(243, 243, 243);
  letter-spacing: 3px;
  font-size: 1.1rem;
  z-index: 2;
}

#logo {
  display: inline;
  margin: 0;
  font-family: 'Lobster Two', cursive;
}

#hamburger {
  /* container around the nav menu */
  float: right;
  font-size: 1.65rem;
}

#hamburger::after {
  clear: both;
  content: "";
  display: table;
}

#hamburger i {
  /* actual nav menu */
  cursor: pointer;
  opacity: 0.45;
}

#hamburger i.active {
  opacity: 1;
}

#nav li {
  margin: 1em 0;
  text-align: center;
  position: relative;
}

@media screen and (min-width: 730px) {
  #nav li::after {
    content: '';
    height: 1px;
    background: white;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -6px;
    opacity: 0;
    transform: scaleX(0);
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
  }

  #nav li:hover::after {
    opacity: 1;
    transform: scaleX(1);
  }
}

#nav li:last-child {
  margin-bottom: 0.5em;
}

#nav a {
  color: whitesmoke;
  opacity: 0.6;
}

#nav a.active {
  opacity: 1;
}

/* text section of home page */

#home__text {
  height: 100vh;
  background: url('./assets/images/background.jpg'), #151729;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#home__text>h1 {
  /* text - HELLO... */
  position: relative;
  margin-bottom: 0.1em;
  font-size: 4rem;
  color: grey;
  text-transform: uppercase;
  visibility: hidden;
}

#home__text>h1::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  border-right: 5px solid rgb(190, 190, 190);
  visibility: visible;
  overflow: hidden;
  animation: hello-text 1.5s ease;
  /* name duration speed count */
  animation-fill-mode: forwards;
  /* to keep the end results of animation */
}

#intro {
  overflow: hidden;
}

#intro h1 {
  /* text - I'm Gautham kumar */
  position: relative;
  top: -100%;
  font-size: 1.8rem;
  font-family: 'Dancing Script', cursive;
  color: rgb(189, 189, 189);
  letter-spacing: 8px;
  text-align: center;
  animation: intro-text 1s ease 1s;
  animation-fill-mode: forwards;
}

#about {
  min-height: 100vh;
  padding: 5.5em 0.3em;
  background: url('./assets/images/bg.jpg');
  background-position: center;
  background-size: cover;
}

.about__section {
  padding: 0em 1em;
}

.about__content {
  /* wrapper for .about__intro and img */
  width: min(100%, 1100px);
  margin: 2.8em auto 0em;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  color: rgb(236, 236, 236);
}

.about__intro {
  max-width: 500px;
  margin-top: 2em;
  padding: 1em 1em 1.5em 1.7em;
  background: transparent;
  box-shadow: inset 10px 10px 15px rgba(0, 0, 0, 0.2),
    inset -10px -10px 15px rgba(255, 255, 255, 0.1),
    10px 10px 15px rgba(0, 0, 0, 0.5),
    -10px -10px 15px rgba(255, 255, 255, 0.1);
  font-size: 1.2rem;
  line-height: 1.7;
}

.about__intro p span {
  color: rgb(184, 184, 184);
  font-size: 2.5rem;
  font-weight: 700;
}

.about__intro a {
  display: inline-block;
  margin-top: 1em;
  padding: 0.3em 0.6em;
  border-radius: 10px;
  color: black;
  background: rgb(192, 192, 192);
}

.about__intro a:hover {
  background: whitesmoke;
}

#about #image {
  width: min(100%, 300px);
  height: 300px;
  border-radius: 50%;
  box-shadow: 10px 10px 10px black,
    -10px -10px 10px black;
  opacity: 0.75;
}

.skills {
  margin: 4.5em auto 0em;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skills__section {
  width: min(100%, 380px);
  min-height: 440px;
  padding: 1em 1.4em;
  margin: 1.5em;
  background: transparent;
  box-shadow: var(--box-shadow);
}

.skills__content {
  margin-top: 0.9em;
}

.skill__name {
  margin: 1em 0em;
  padding: 1em 1em 1em 1.5em;
  background: transparent;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: inset -5px -5px 15px rgba(255, 255, 255, 0.3),
    5px 5px 15px rgba(0, 0, 0, 0.5);
  color: rgb(179, 179, 179);
  font-weight: 700;
}

/* For Works and projects page */
#works {
  padding: 5.5em 0.3em;
  background: #232427;
}

#works .container {
  margin-bottom: 20px;
}

.cards {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.card {
  /* outer container of card */
  width: min(100%, 380px);
  min-height: 440px;
  margin: 25px 20px;
  border-radius: 15px;
  box-shadow: var(--box-shadow);
  position: relative;
}

.card .box {
  /* inner container of card */
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a2b2f;
  border-radius: 15px;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.4);
  transition: 0.5s;
}

.card .box::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 2552, 255, 0.05);
  pointer-events: none;
}

.card .box:hover {
  transform: translatey(-50px);
  box-shadow: 0px 50px 50px rgba(0, 0, 0, 0.4);
}

.card .box .content {
  text-align: center;
  padding: 0.5em 1em;
}

/* project numbers - 01, 02, 03.. */
.card .box .content h1 {
  position: absolute;
  top: -15px;
  right: 35px;
  font-size: 10rem;
  color: rgba(255, 255, 255, 0.05);
}

/* project title and description */
.card .box .content h2,
.card .box .content p {
  color: rgba(255, 255, 255, 0.5);
  z-index: 1;
}

.card .box .content h2 {
  font-size: 2.3rem;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 1rem;
}

/* 'View site' Link */
.card .box .content a {
  position: relative;
  display: inline-block;
  margin-top: 1em;
  padding: 0.6em 0.95em;
  background: rgba(0, 0, 0, 0.6);
  color: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 13px 13px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.card .box .content a:hover {
  background: rgba(0, 0, 0, 1);
}

/* animation around 'View Site' Link */
.card .box .content a span {
  position: absolute;
}

.card .box .content a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 3px;
  background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.6));
  animation: siteLink-top 1s linear infinite;
}

.card .box .content a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 3px;
  height: 100%;
  background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.6));
  animation: siteLink-right 1s linear 0.25s infinite;
}

.card .box .content a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 3px;
  background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.6));
  animation: siteLink-bottom 1s linear 0.5s infinite;
}

.card .box .content a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 3px;
  height: 100%;
  background: linear-gradient(to top, transparent, rgba(255, 255, 255, 0.6));
  animation: siteLink-left 1s linear 0.75s infinite;
}

/* contact page */
#contact {
  min-height: 100vh;
  padding: 5.5em 1.3em;
  background: #1e1e20;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contact__title {
  margin-bottom: 1em;
}

#msg-result {
  color: rgb(226, 226, 226);
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 1em;
}

.contact__title::before,
.contact__title::after {
  border-bottom: 3px solid #1e1e20;
}

#contact form {
  width: min(100%, 380px);
  height: max-content;
  margin: 0 auto;
  padding: 20px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.contact-left,
.contact-right {
  display: flex;
  flex-direction: column;
}

.c-l-heading {
  width: max-content;
  margin: 0 auto;
  font-size: 1.7rem;
  letter-spacing: 2px;
  color: #afafaf;
  font-weight: 600;
  text-align: center;
}

.f-name,
.f-email {
  margin-top: 1.1em;
  display: flex;
  flex-direction: column;
}

.f-name p,
.f-email p {
  color: #bfbfbf;
  font-size: 1.3rem;
  font-weight: 500;
}

.f-name input,
.f-email input {
  width: 100%;
  height: 40px;
  padding: 10px;
  background-color: transparent;
  border: none;
  outline: none;
  color: #bbbbbb;
  border-bottom: 1px solid #929292;
  margin-bottom: 10px;
}

.f-name input::placeholder,
.f-email input::placeholder {
  opacity: 0.5;
  letter-spacing: 2px;
}

.f-name input:focus,
.f-email input:focus {
  border-bottom: 1px solid white;
  transition: all ease 0.4s;
}

.message {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
}

.message p {
  color: #a7a7a7;
  font-weight: 500;
  font-size: 1.25rem;
}

.message textarea {
  width: 100%;
  height: 200px;
  margin: 0.62em 0px;
  padding: 0.8em;
  border: none;
  outline: none;
  color: #ebebeb;
  background-color: #262626 !important;
  letter-spacing: 1px;
  font-family: calibri;
  font-size: 1.15rem;
  resize: none;
}

.message textarea::placeholder {
  font-size: 1.125rem;
  letter-spacing: 2px;
  color: white;
}

.contact-right button {
  width: 100%;
  height: 50px;
  background-color: rgb(0, 0, 0);
  font-weight: 600;
  letter-spacing: 1px;
  border: none;
  outline: none;
  color: rgb(192, 192, 192);
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 14px;
}

.contact-right button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(23, 23, 23);
}

footer .container {
  width: min(90%, 1430px);
}

footer .social {
  width: min(100%, 270px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

footer i {
  font-size: 1.5rem;
  color: rgb(172, 172, 172);
  cursor: pointer;
}

footer i:hover {
  color: white;
}

/* section titles - about, projects, contact, frontend, backend */
.works__title,
.contact__title,
.about__title,
.skills__title {
  position: relative;
  width: max-content;
  margin: 0 auto 0.7em;
  border-bottom: 3px solid rgb(182, 182, 182);
  font-size: 1.8rem;
  color: aliceblue;
  opacity: 0.75;
  letter-spacing: 3px;
}

.works__title::before,
.contact__title::before,
.about__title::before,
.skills__title::before {
  position: absolute;
  top: 0;
  left: 0;
}

.works__title::after,
.contact__title::after,
.about__title::after,
.skills__title::after {
  position: absolute;
  top: 0;
  right: 0;
}

.works__title::before,
.works__title::after,
.contact__title::before,
.contact__title::after,
.about__title::before,
.about__title::after,
.skills__title::before,
.skills__title::after {
  content: '';
  width: 0%;
  height: 100%;
  border-bottom: 3px solid #232427;
  animation: section-title 2s linear infinite;
}

/* Animations */
@keyframes hello-text {
  0% {
    width: 0%;
  }

  100% {
    width: 100%;
  }
}

@keyframes intro-text {
  100% {
    top: 0%;
  }
}

@keyframes section-title {

  0%,
  10%,
  90%,
  100% {
    width: 0%;
  }

  50% {
    width: 50%;
  }
}

@keyframes siteLink-top {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

@keyframes siteLink-right {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

@keyframes siteLink-bottom {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

@keyframes siteLink-left {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

@media screen and (min-width: 550px) {
  #home__text>h1 {
    font-size: 5rem;
  }

  #intro h1 {
    font-size: 1.9rem;
  }

  #works {
    padding: 5.5em 2em;
  }

  #about #image {
    width: 350px;
    height: 350px;
  }
}

@media screen and (min-width: 700px) {
  #home__text>h1 {
    font-size: 6rem;
  }

  #intro h1 {
    font-size: 2.1rem;
  }

  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #nav {
    font-size: 1.2rem;
  }

  #nav li,
  #nav li:last-child {
    margin: 0;
    margin-left: 2em;
    display: inline;
  }

  #works {
    padding: 5.5em 4em;
  }

  .works__title {
    margin: 0 auto 1em;
  }

  #contact form {
    width: min(100%, 1000px);
    height: min(65vh, 700px);
    display: flex;
    justify-content: space-around;
  }

  .contact-left {
    width: 37.5%;
  }

  .contact-right {
    width: 57.5%;
  }

  .c-l-heading,
  .message {
    margin: 0;
  }

  .message {
    height: 90%;
  }

  .message textarea {
    height: 90%;
    padding: 1em;
  }

  .f-name input,
  .f-email input {
    font-size: 0.9rem;
  }
}

@media screen and (min-width: 900px) {
  .about__content {
    flex-direction: row;
    align-items: stretch;
  }

  .about__intro {
    max-width: 600px;
    margin: 0;
    margin-right: 2em;
  }
}